import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataGrid from 'react-data-grid';
import { useParams } from "react-router-dom";

const ViewPage = () => {

    const listIdVal = useParams()['listid'];
    const hashPasswordVal = window.location.hash.replace('#', '');

    console.log("url-", window.location, " -+- ", listIdVal, "-+-", hashPasswordVal);

    const [ajaxData, setAjaxData] = useState({});
    const [listData, setListData] = useState({});
    const columns = [
        { key: 'value', name: 'List Values' }
      ];

    const dataConverterForDataGrid = function(resData) {
        console.log("LEN-->", resData.length);
        setAjaxData(resData);

        let listDataLen = resData.length;
        let rows = [];
        if(listDataLen){
            for(let i=0; i < listDataLen; i++){
                const tempItem = resData[i];
                let nformat = { value:  tempItem };
                rows.push(nformat);
            }
        }

        //console.log(rows[1]);
        return rows;
    }
    const downloadDataAsFile = function() {

        // convert array to string format.
        let listDataAsString = "";
        let listDataLen = ajaxData.length;
        if(listDataLen){
            for(let i=0; i < listDataLen; i++){
                const tempItem = ajaxData[i];
                //console.log(typeof tempItem, "---", tempItem)
                listDataAsString = listDataAsString + tempItem + "," ;
            }
        }

        let filename = 'arraylist.txt';
        const blob = new Blob([listDataAsString], {type: 'text/csv;charset=utf-8;'});
        if(window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        }
        else{
            const elem = window.document.createElement('a');
            //elem.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(listDataAsString));
            elem.setAttribute('download', filename);                
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;        
            elem.style.display = 'none';
            document.body.appendChild(elem);
            elem.click();        
            document.body.removeChild(elem);
        }
    }
    useEffect(() => {

        /*const payload = {
            listId: "wwnmec",
            listPassword: "6gdadi",
            startIndex: 1,
            endIndex: -1
        }*/
        const payload = {
            listId: listIdVal,
            listPassword: hashPasswordVal,
            startIndex: 1,
            endIndex: -1
        }
        console.log("Making Ajax Call for fetch list values --");
        //axios.post("https://api.arraylist.org/fetch-list-values/", { payload }).then(function(res){
        /*axios.post("https://localhost:3333/fetch-list-values/", { payload }).then(function(res){
            let formatedData = dataConverterForDataGrid(res.data);
            setListData( formatedData )
        });*/
        //const fetchURL = 'http://localhost:3333/fetch-list-values/';
        const fetchURL = 'https://api.arraylist.org/fetch-list-values/';
        axios({
            method:'post',
            url:fetchURL, 
            withCredentials: false,
            data: payload,
            crossDomain: true,
        }).then(function(res) {
            let formatedData = dataConverterForDataGrid(res.data);
            setListData( formatedData )
        });

        console.log("-------->RRRRRRRRemove test urls ", payload.listId);
        //let geturl = "https://api.arraylist.org/fetch-list-values/";
        /*let geturl = "https://api.arraylist.org/fetch-list-test123/" + payload.listId + "/" + payload.listPassword;
        //let geturl = "https://api.arraylist.org/fetch-list-test123/wwnmec/6gdadi"
        console.log("geturl ==== >", geturl );
        axios.get( geturl ).then(function(res){
            let formatedData = dataConverterForDataGrid(res.data);
            setListData( formatedData )
        });*/


    }, []);

    return (
        <div className="flex-grow">
            <div className='bg-gray-50 p-4'><span className=" text-xl font-bold">The following are the values added to your list </span></div>
            <div>
            {
              listData ? 
                <DataGrid columns={columns} rows={listData} />  
                : null 
            }
            </div>
            <div className="p-6">
                <button className= {"bg-blue-600 text-white text-base font-semibold rounded-md shadow-md py-2 px-8  hover:bg-indigo-600 "  }
                      type="button"
                      onClick={() => downloadDataAsFile()}
                    >
                      Download List Data
                </button>
            </div>
        </div>
    );
  };
  
  export default ViewPage;
  