import React from "react";

const DivLink = (props) => {
    const copyToClipboard = (urlToCopy) => {
        navigator.clipboard.writeText(urlToCopy);
    };
    const completeLink = props.text1 + props.text2 + "/" + props.text3

    return(<div className="flex w-full flex-wrap px-1 text-white bg-black  shadow rounded overflow-hidden">
            <div className="flex justify-center items-center w-full text-center text-xl text-gray-200 m-3">

                <div className="text-l flex justify-center items-center">

                    <a target="_blank" href={completeLink}>{props.text1}</a> 

                    <span className="text-blue-500">
                        {props.text2} 
                    </span>
                    <span>/</span>
                    <span className="text-pink-500">
                        {props.text3} 
                    </span>                    
                    <div className=' flex justify-center  ml-2 mt-1 '>
                        <button className='justify-end hover:bg-indigo-600' onClick={() => copyToClipboard(completeLink)}>
                            <img className="text-blue-300" src="copy.png" width="20px" />
                        </button>
                    </div>

                </div>
            </div>

        </div> );
}
//-----------------------
export default function ListDialog(props) {
    const listDialog = props.listDialog;
    const showListDialog = props.showListDialog;

    console.log(props.listData);
  
    let listId   = props.listData.shortPublicURL;
    let listPass = props.listData.passwordForPrivateAccess;
    return (
        <>
        {listDialog ? (
          <>
            <div className="justify-center bg-blue-400 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto  my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg mx-4 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t bg-gray-100 ">
                    <h3 className="text-3xl font-bold">
                      Here is your new List
                    </h3>
                    {/*<button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        X-x
                      </span>
                    </button> */}
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <p className="my-4 text-black text-lg font-bold leading-relaxed">
                      URL for adding a value to your list.
                    </p>
  
                    <DivLink text1={"https://api.arraylist.org/add/"} text2={listId} text3="hi@gmail.com" />
                    <p className="mt-2 text-black text-normal leading-relaxed">
                    You can also set it as Form action. (It works with both GET and POST methods)
                    </p>
                    {/*<p className="mt-2 mb-4 text-gray-600 text-base leading-relaxed">
                    You can also set it as Form action. (It works with both GET and POST methods)
                    </p>*/}

                    <p className="mt-10 mb-4 text-black text-lg font-bold leading-relaxed">
                      To view your list data use the following link.
                    </p>
                    
                    <DivLink text1={"https://arraylist.org/view/" } text2={listId} text3={"#" + listPass } />
  
                    <p className="mt-4 rounded  text-red-500 text-lg font-bold leading-relaxed">
                      Please save this link, you can't retrieve it later.
                    </p>
                  </div>
  
  
                  {/*footer*/}
                  <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b bg-gray-100">
                    {/*<button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => showListDialog(false)}
                    >
                      Close
                    </button> */}
                    <button
                      className="bg-pink-500 hover:bg-pink-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => showListDialog(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
          ) : null}                  
          </>
    );
  }


