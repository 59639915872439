const Footer = () => {

    return(    <footer className=" ">
    <div className="container mx-auto px-2 py-2">
      <div className="w-full flex flex-col md:flex-row ">

        <div className="flex-1">

          <ul className="list-reset text-center">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a target="_blank" href="https://tealpod.notion.site/ArrayList-5c222e145389443d861b11d6bedbb517"
                className="font-bold no-underline hover:underline text-gray-800 hover:text-orange-500" rel="noreferrer" 
                >FAQ</a
              >
            </li>
          </ul>
        </div>
        <div className="flex-1">

          <ul className="list-reset text-center">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a className="font-bold no-underline hover:underline text-gray-800 hover:text-orange-500" rel="noreferrer" 
                target="_blank" href="https://twitter.com/prakis">Twitter</a
              >
            </li>
          </ul>
        </div>            
        <div className="flex-1">

          <ul className="list-reset text-center">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                Powered by 
              <a className="font-bold no-underline hover:underline text-gray-800 hover:text-orange-500" rel="noreferrer" 
                target="_blank" href="https://railway.app"> Railway.app</a
              >
            </li>
          </ul>
        </div>            

      </div>
    </div>
  </footer>);
  }

  export default Footer;