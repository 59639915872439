//import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import ViewPage from './pages/ViewPage';
import HelpPage from './pages/HelpPage';

import Header from './component/header';
import Footer from './component/footer';

function App() {
  return (
    <div className="App flex flex-col min-h-screen">

        <BrowserRouter>

            <Header/>

            <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route exact path="/view/:listid" element={<ViewPage />} />
                <Route exact path="/help" element={<HelpPage />} />                
            </Routes>

            <Footer/>

        </BrowserRouter>        
    </div>
  );
}

export default App;
