import React from "react";

export default function ProgressDialog(props) {
 
    return (
          <>
            <div className="justify-center bg-blue-400 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl ">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
                  {/*header*/}
                    <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t bg-gray-200 ">
                        <h3 className="text-3xl font-bold">
                        Your new List is getting ready
                        </h3>
                    </div>                    
                  <div className="relative p-6 flex-auto bg-white rounded-b flex justify-center">
                    {/*<p className="my-4 text-black text-lg leading-relaxed">
                      Your list is being created...
                    </p> */}
                    <img src="ellipsis-2-4s-200px.gif" />
                  </div>


              </div>
            </div>
            </div>
          </>
    );
  }