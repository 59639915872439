

const HelpPage = () => {

    
    return (
        <div className="flex-grow">
            From Help page
        </div>
    );
  };
  
  export default HelpPage;
  