const Header = () => {

    let headerFullHeight = window.location.pathname === "/" ? true : false;
    let headerSpace = headerFullHeight? " lg:py-6 " : " py-2 ";

    return (    
  <nav id="header" className={ "w-full z-30 top-0 text-white " }>
    <div className= {"w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 " + headerSpace }>
      <div className="flex items-center ">
        <a className="text-white no-underline hover:no-underline font-bold text-2xl md:text-3xl lg:text-5xl" href="/" rel="noreferrer" >
            <span className="text-blue-600">Array</span><span className="text-pink-600">List</span>
        </a>
      </div>
    </div>
  </nav>);
}

export default Header;